import React from 'react';
import { HashRouter, BrowserRouter, Route, Routes } from 'react-router-dom';
import { Inicio } from './components/components-pages/Inicio';
import { DJ } from './components/components-pages/DJ';
import { ProduccionMusical } from './components/components-pages/ProduccionMusical';
import { AlquilerEquipos } from './components/components-pages/AlquilerEquipos';
import { QueEsGroove } from './components/components-pages/QueEsGroove';
import { IniciarSesion } from './components/components-pages/IniciarSesion';
import { Navegacion } from './app-router/Navegacion';
import { Menu } from './app-router/Menu';
import './app-router/Navegacion.css';
import './app-router/Menu.css';
import { Footer } from './app-router/Footer';




export const AppRouter = () => {
  return (
    <>
        <BrowserRouter>
            <body>

                <div className='margen-menu'>

                <header className=' position-fixed w-100 top-0 navegacion'>
                    <Navegacion/>
                </header>

                    <div className='menu'>
                        <Menu/>
                    </div>

                    {/* RUTAS */}
                    <Routes>
                    {/* Definimos las rutas y sus respectivos componentes */}
                    <Route path="/" element={<Inicio />} />
                    <Route path="/dj" element={<DJ />} />
                    <Route path="/produccion-musical" element={<ProduccionMusical />} />
                    <Route path="/alquiler-equipos" element={<AlquilerEquipos />} />
                    <Route path="/que-es-groove" element={<QueEsGroove />} />
                    <Route path="/iniciar-sesion" element={<IniciarSesion />} />
                    <Route path="/*" element={<Inicio />} />

                    </Routes>
                </div>




                <Footer/>
                
            </body>
        </BrowserRouter>
    </>
  )
}