import djCdj from "./img/1.jpg";
import djAvanzado from "./img/2.jpg";
import djTraktor from "./img/3.jpg";
import djVinilos from "./img/4.jpg";
import djUrbano from "./img/5.jpg";





export const ArrayCursos = [
    {
        titulo:"DJ CON CDJ",
        img: djCdj,
        descipcion:"Si buscas iniciarte como DJ, en este curso vas a aprender todo lo necesario como sincronización, conexiones de equipamiento, uso de efectos, análisis y búsqueda de música, géneros musicales dentro de la electrónica, selección musical, warm up y mucho más para que estés listo al momento de una presentación."
    },
    {
        titulo:"DJ AVANZADO",
        img: djAvanzado,
        descipcion:"Preparamos este curso para aquellos que ya son dj o han finalizado 'Dj con cdj' y busquen destacarse en sus presentaciones. Aprenderás a incorporar en tus sets el uso de samples, acapellas, combinación de efectos, apertura y cierre de set, configuración de los equipos; utilizando 3 o 4 cdj 's. También aprenderás a utilizar y conectar otros equipos tales como mixer xone 92/96 y la efectera RMX 1000 de Pioneer DJ."
    },
    {
        titulo:"DJ TRAKTOR",
        img: djTraktor,
        descipcion:"Diseñamos este curso para que puedas potenciar tu creatividad con las posibilidades que brinda Traktor Pro. Aprenderás a mezclar utilizando 4 decks, combinando efectos, agregando loops y samples, permitiéndote generar más dinamismo en tu set. También aprenderás a configurar y mapear el setup."
    },
    {
        titulo:"DJ CON VINILOS",
        img: djVinilos,
        descipcion:"En este curso vas desarrollarás habilidades técnicas y creativas específicas para mezclar música usando bandejas y discos de vinilo. Aprenderás a cómo calibrar y mantener estos equipos. Y por último aprenderás a mezclar utilizando el formato Timecode de Traktor es una tecnología que permite a los DJs controlar software de DJing utilizando vinilos."
    },
    {
        titulo:"DJ URBANO",
        img: djUrbano,
        descipcion:"Este curso está enfocado en técnicas y habilidades específicas para el DJing en géneros musicales populares en la cultura urbana, como el reggaetón, trap, hip-hop, R&B y otros estilos similares. Selección, organización, técnicas de mezclas, uso de loops y samples, equipos y software, lectura de público y desarrollo de estilo personal, son algunos de los temas que se desarrollan en el curso."
    },
];