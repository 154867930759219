import React from 'react';
import './TarjetasCursos.css';
import { Link } from 'react-router-dom';


export const TarjetasCursos = ({ArrayCursos}) => {
  return (
    <div className='container mt-5 mb-5'>
      <div className='row ancho-maximo'>
        {ArrayCursos.map((curso, index) => {
          return (
            <div key={index} className="col-12 col-md-4 mb-5">
              <div className="card sombra">
                <img src={curso.img} className="card-img-top" alt="imagen del curso" />
                <div className="card-body tarjeta">
                  <h5 className="card-title fs-5 fw-bold">{curso.titulo}</h5>
                  <p className="card-text fs-6 fw-bold opacity-50"><small class="text-body-secondary">{curso.descipcion}</small></p>
                </div>
                {/* <ul className="list-group list-group-flush">
                  <li className="list-group-item">An item</li>
                  <li className="list-group-item">A second item</li>
                  <li className="list-group-item">A third item</li>
                </ul> */}
                <div className="card-body d-flex">
                  <Link to="/inicio" type='button' className="btn btn-outline-info opacity-50 fw-semibold p-2 m-0 me-4">Más</Link>
                  <Link to="/inicio" type='button' className="btn btn-outline-info opacity-50 fw-semibold p-2 m-0 me-4">Comprar</Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>

  )
}
