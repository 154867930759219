import React from 'react';
import { Link } from 'react-router-dom';


export const Navegacion = () => {
  return (
    <nav className="navbar navbar-expand-lg" data-bs-theme="dark">
        <div className="mx-auto">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 d-flex flex-row align-items-center fw-normal text-center">
                <li className="nav-item mx-2">
                    <Link className="nav-link" to="/">Inicio</Link>
                </li>
                <li className="nav-item mx-2">
                    <Link className="nav-link" to="/dj">DJ</Link>
                </li>
                <li className="nav-item mx-2">
                    <Link className="nav-link" to="/produccion-musical">Producción</Link>
                </li>
                <li className="nav-item mx-2">
                    <Link className="nav-link" to="/alquiler-equipos">Alquileres</Link>
                </li>
                <li className="nav-item mx-2">
                    <Link className="nav-link" to="/que-es-groove">Sobre Groove</Link>
                </li>
                <li className="nav-item mx-2">
                    <Link className="nav-link" to="/iniciar-sesion">Iniciar sesión</Link>
                </li>
            </ul>
        </div>
    </nav>
  )
}
