import React from 'react';
import { Link } from 'react-router-dom';

export const Menu = () => {
  return (
    <nav className="navbar fixed-top">
        <div className="container-fluid">

            <button className="navbar-toggler m-2 opacity-100 p-0 m-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                <div className='bg-dark w-100 p-2 rounded' style={{opacity:"70%"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" fill="white" class="bi bi-list" viewBox="3 3 10 10">
                        <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                    </svg>

                </div>
            </button>


            <div className="offcanvas offcanvas-start text-bg-dark w-50" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title px-3" id="offcanvasDarkNavbarLabel">Menú</h5>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                
                <div className="offcanvas-body">
                    <ul className="navbar-nav flex-grow-1 px-3">
                        <Link className="nav-link w-100" to="/">
                            <li className="nav-item mx-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h6>Inicio</h6>
                            </li>
                        </Link>
                        <Link className="nav-link w-100" to="/dj">
                            <li className="nav-item mx-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h6>DJ</h6>
                            </li>
                        </Link>
                        <Link className="nav-link w-100" to="/produccion-musical">
                            <li className="nav-item mx-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h6>Producción</h6>
                            </li>
                        </Link>
                        <Link className="nav-link w-100" to="/alquiler-equipos">
                            <li className="nav-item mx-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h6>Alquileres</h6>
                            </li>
                        </Link>
                        <Link className="nav-link w-100" to="/que-es-groove">
                            <li className="nav-item mx-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h6>Sobre Groove</h6>
                            </li>
                        </Link>
                        <Link className="nav-link w-100" to="/iniciar-sesion">
                            <li className="nav-item mx-2" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <h6>Iniciar sesión</h6>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
  )
}
