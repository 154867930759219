import React from 'react';
import ProduccionImage from './dj/img/Produ-img-header.jpg';
import './dj/DJ.css';
import { TarjetasCursos } from './dj/TarjetasCursos';
import { ArrayProduccionMusical as ArrayCursos } from './produccion-musical/ArrayProduccionMusical';



export const ProduccionMusical = () => {
  return (
    <div>
      <header  
            // className='imagen' 
            style={{ 
                backgroundImage: `url(${ProduccionImage})`,
                backgroundSize: "cover", 
                backgroundPosition: "center",
                width:"100%",
                height:"500px",
                position:"relative",
            }}
        >

            {/* Overlay oscuro con opacidad al 10% */}
            <div className='overlay-img'></div>

            <div className='texto-header'>
                <h1 className='mb-4 text-info'>Cursos de Producción Musical</h1>
                <h6 className='fw-normal fs-6'>
                    Si buscas iniciarte como DJ, en estos cursos vas a aprender todo lo necesario, como sincronización, conexiones, uso de efectos, selección musical, warm up y mucho más para que estés listo al momento de una presentación.

                </h6>
            </div>
        </header>

        <body>
            <TarjetasCursos ArrayCursos={ArrayCursos} />
        </body>
    </div>
  )
}
