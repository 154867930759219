import produccionInicial from "../produccion-musical/img/01.jpg";
import produccionAvanzada from "../produccion-musical/img/02.jpg";
import teoriaMusical from "../produccion-musical/img/03.jpg";
import mezclaYMaster from "../produccion-musical/img/04.jpg";





export const ArrayProduccionMusical = [
    {
        titulo:"PRODUCCIÓN INICIAL",
        img: produccionInicial,
        descipcion:"Creá tu propia música desde cero, utilizando Ableton Live. En esta capacitación desarrollaremos temas sobre uso de sintetizadores, samplers, efectos, conceptos básicos de teoría musical (escalas, acordes y progresiones armónicas), creaciones de patrones rítmicos, estructura, mezcla y mastering básico para poder finalizar tu proyecto musical."
    },
    {
        titulo:"PRODUCCIÓN AVANZADA",
        img: produccionAvanzada,
        descipcion:"Aprenderás a perfeccionar tus habilidades de producción y a explorar técnicas más complejas para llevar tus creaciones a un nivel profesional. Diseño de sonido, procesamiento creativo de audio, optimización de flujo de trabajo, tecnicas de mezcla, son algunos de los temas que se desarrollan en las clases."
    },
    {
        titulo:"TEORÍA MUSICAL",
        img: teoriaMusical,
        descipcion:"Te brindamos las herramientas para entender y crear música de manera más estructurada y creativa. Aprenderás los conceptos esenciales como escalas, acordes, armonía, ritmo, y formas musicales, para crear melodías, armonías, expresar tus ideas y emociones a través de la música."
    },
    {
        titulo:"MIXING & MASTERING",
        img: mezclaYMaster,
        descipcion:"Aprenderás una variedad de habilidades y técnicas esenciales para transformar tus producciones musicales asegurando que suenen claras, potentes y profesionales, listas para ser distribuidas en cualquier formato. Ecualización, equilibrio de volumen, compresión, panoramización, efectos como delay y reverb, son algunos de los temas en donde profundizaremos el contenido."
    },
];